// colors

$brandColor-darkGreen: #024959;
$brandColor-peach: #eab657;
$brandColor-orange: #e38439;
$brandColor-beige: #F24738;
$brandColor-gray: #d8d2cd;

$dark-gray: #333539;
$light-gray: #cccccc;
$off-white: #f4f4f4;
$yolk-yellow: #f2bc19;
$graphic-blue: #3475bc;
$eraser-pink: #da2b5c;

$copyColor: #111111;

// Rainbow Grid
// $rainbowColor1: #425B8C;
// $rainbowColor2: #F2D649;
// $rainbowColor3: #D9D5CC;
// $rainbowColor4: #BF9A54;
// $rainbowColor5: #F26513;
// $rainbowColor6: #4C9EAA;
// $rainbowColor7: #B96536;
// $rainbowColor8: #263650;
// $rainbowColor9: #D4A659;
// $rainbowColor10: #F7C6BF;
// $rainbowColor11: #092140;
// $rainbowColor12: #024959;
// $rainbowColor13: #F2C777;
// $rainbowColor14: #F24738;
// $rainbowColor15: #BF2A2A;
// $rainbowColor16: #4C6BA1;
// $rainbowColor17: #D9CBBE;
// $rainbowColor18: #409544;




// breakpoints
$phone-small: 320px;
$phone-small-landscape: 568px;
$phone-medium: 375px;
$phone-large: 414px;
$phone-large-portrait: 667px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$desktop: 1280px;
$desktop-wide: 1500px;