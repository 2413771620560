/* Input style */
.input-container,
.textarea-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 24px) scale(0.8);
  opacity: 0.5;
}

.input-container .filled {
  transform: translate(0, 24px) scale(0.8);
  opacity: 0.5;
}

.input-container label {
  position: absolute;
  bottom: 45px;
  pointer-events: none;
  transform: translate(0, 40px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  height: 40px;
  border: none;
  @include font(18, 24);
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: white;
}




.textarea-container {
  margin-top: 20px;
}

.textarea-container textarea {
  color: white;
}

.textarea-container textarea:focus {
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textarea-container:focus-within label {
  transform: translate(0, -22px) scale(0.8);
  opacity: 0.5;
}

.textarea-container .filled {
  transform: translate(0, -22px) scale(0.8);
  opacity: 0.5;
}

.textarea-container label {
  position: absolute;
  top: 0px;
  pointer-events: none;
  transform: translate(0, 10px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}




/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $off-white inset !important;
}