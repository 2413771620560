.homeSlider {
  display: grid;
  width: 100%;
  height: calc(100vh - 80px);

  &__slide {
    position: relative;
    display: block;
    height: 100%;
    // min-height: calc(100vh + 40px);
    min-height: 100vh;

    &__content {
      position: absolute;
      top: calc(50% - 50px);
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      object-position: center center;
    }


  }

  .slide1 {
  }

  .slide2 {
  }

  .slide3 {
  }
}


.leftArrow {
  position: absolute;
  top: calc(50% - 20px);
  left: 40px;
  width: 30px;
  height: 40px;
  font-size: 40px;
  padding: unset;
  z-index: 1;
  cursor: pointer;
}

.rightArrow {
  position: absolute;
  top: calc(50% - 20px);
  right: 40px;
  width: 30px;
  height: 40px;
  font-size: 40px;
  padding: unset;
  z-index: 1;
  cursor: pointer;
}