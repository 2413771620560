
   span.switcher {
      position: absolute;
      right: 0;
      width: 185px;
      margin-right: 25px;
      display: none;

      @include respond-to($tablet-landscape) {
        display: block;
      }

      input {
         appearance: none;
         position: relative;
         width: 185px;
         height: 36px;
         border-radius: 25px;
         background-color: $dark-gray;
         outline:none;
         border: 1px solid $dark-gray; 
         cursor: pointer;

         &:before, &:after {
            z-index: 2;
            position: absolute;
            top:50%;
            transform:translateY(-50%);
            color: white;
         }
         &:before {
            content: 'WORK';
            left: 20px;
            font-weight: 700;
         }
         &:after {
            content: 'PARTY';
            right: 20px;
            font-weight: 700;
         }
      }
      label {
         z-index: 1;
         position: absolute;
         top: 10px;
         bottom: 10px;
         height: 22px;
         border-radius: 20px;
      }
      &.switcher-1 {
        cursor: pointer;
         input {
            transition:.12s -.05s;

            &:not(:checked) {
               background-color: white;

               &:before {
                  color: white;
                  transition: color .25s .1s;
               }

               &:after {
                  color: $dark-gray;
                  transition: color .25s;
               }

               &+label {
                  left: 12px;
                  right: 90px;
                  background: $dark-gray;
                  transition: left .25s, right .2s .1s;
               }
            }
            &:checked {
               background: $dark-gray;
               transition: background .25s -.05s;

               &:before {
                  color: white;
                  transition: color .25s;
               }

               &:after {
                  color: $dark-gray;
                  transition: color .25s .1s;
               }

               &+label {
                  left: 100px;
                  right: 5px;
                  background:white;
                  transition: left .2s .1s, right .25s, background .17s -.05s;
               }
            }
         }
      }
   }
